import React, { useEffect, useState } from 'react';
import './Header.css';

const Header = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    return (
        <header className="header">
            <div className="logo">ESPN</div>
            <div className="clock">{formatTime(time)}</div>
            <nav>
                <ul>
                    <li>Olympic Sports</li>
                    <li>Football</li>
                    <li>Cricket</li>
                    <li>F1</li>
                    <li>NBA</li>
                    <li>Badminton</li>
                    <li>...</li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
