import React from 'react';
import ArticleCard from './ArticleCard';
import './MainContent.css';

const MainContent = () => {
    return (
        <main className="main-content">
            <ArticleCard />
        </main>
    );
};

export default MainContent;
