import React from 'react';
import './Sidebar.css';

const Sidebar = () => {
    return (
        <aside className="sidebar">
            <div className="quick-links">
                <h3>Quick Links</h3>
                <a href="#">Team India Medals 2020</a>
            </div>
            <div className="favorites">
                <h3>Favorites</h3>
                <button>Manage Favorites</button>
            </div>
            <div className="customize-espn">
                <button>Create Account</button>
                <button>Log In</button>
            </div>
            <div className="fantasy">
                <h3>Fantasy</h3>
            </div>
        </aside>
    );
};

export default Sidebar;
