import React from 'react';
import './ArticleCard.css';

const ArticleCard = () => {
    return (
        <div className="article-card">
            <img src="olympic-sports/public/images/Screenshot 2024-07-24 181836.png" alt="Neeraj Chopra" />
            <h2>Olympics 2024: Who are India's best medal prospects in Paris?</h2>
            <p>Keeping an optimistic outlook, here are India's top 12 medal contenders for the 2024 Paris Olympics:</p>
            <p>By Sunaadh Sagar</p>
        </div>
    );
};

export default ArticleCard;
